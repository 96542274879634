@tailwind base;
@tailwind components;
@tailwind utilities;

.AltoBody {
  @apply relative min-h-screen pb-[72px];
}

.AltoFooter {
  @apply absolute bottom-0 w-full;
}

.inputCss {
  @apply  block w-full bg-white text-blue-900 font-medium border border-blue-400 focus:border-[3px] rounded-lg py-3 px-3 leading-tight focus:outline-none;
}

.inputCssAssociates {
  @apply  block w-full bg-white text-blue-900 font-medium border border-blue-400 focus:border-[3px] rounded-lg py-2 pr-9 pl-3 leading-tight focus:outline-none;
}

.inputCssError {
  @apply  block w-full bg-red-50 text-red-900 font-medium border border-red-400 focus:border-[3px] rounded-lg py-3 px-3 leading-tight focus:outline-none;
}

.inputPasswordCss {
  @apply  block w-full bg-white text-blue-900 font-medium border border-blue-400 focus:border-[3px] rounded-lg py-3 pr-12 pl-3 leading-tight focus:outline-none;
}

.inputPasswordCssError {
  @apply  block w-full bg-red-50 text-red-900 font-medium border border-red-400 focus:border-[3px] rounded-lg py-3 pr-12 pl-3 leading-tight focus:outline-none;
}

.labelCss {
  @apply block uppercase tracking-wide text-blue-900 text-xs font-bold mb-2;
}

.labelCssPay {
  @apply block w-full text-blue-900 text-3xl font-bold;
}

.labelCssAssociates {
  @apply block uppercase tracking-wide text-blue-900 text-xs font-bold;
}

.labelCssTitleAssociated {
  @apply block uppercase tracking-wide text-blue-900 text-sm font-bold mb-6;
}

.labelCssIndicator {
  @apply block uppercase tracking-wide text-sm font-bold;
}

.labelCssQuery {
  @apply block w-full text-gray-500 text-sm font-bold;
}

.labelCssUpload {
  @apply block uppercase tracking-wide text-blue-900 text-lg font-bold mb-2;
}
