/* ESTILOS GENERALES */
.wrap {
    margin: 0px auto;
}

.clearfix {
    float: none;
    clear: both;
}

/* ----------------- */

/* BANNER */
#banner {
    width: 95.5%;
    height: 100px;
    border: 10px solid white;
    box-shadow: 0px 0px 2px gray;
    background: url("/public/assets/images/banner6.jpg");
    background-position: -200px -200px;
    margin: 20px auto;
    overflow: hidden;

    /* animacion banner */
    animation: backBanner 120s linear;
}

@keyframes backBanner {
    0% {
        background-position: 0px 0px;
    }

    100% {
        background-position: -200px -200px;
    }
}

#banner h1 {
    display: block;
    width: 850px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: white;
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 2px;
    text-shadow: 2px 2px 1px black;
    margin: 27px auto;

    /* animacion texto */
    animation: showText 10s linear;
}

@keyframes showText {
    0% {
        transform: translateX(-600%) scale(5, 5);
        opacity: 1;
    }

    50% {
        transform: translateX(600%) scale(5, 5);
        opacity: 1;
    }

    75% {
        transform: translateX(-600%) scale(5, 5);
        text-shadow: none;
        color: transparent;
        opacity: 0;
    }

    100% {
        transform: translateX(0%) scale(1, 1);
        text-shadow: 2px 2px 1px black;
        color: white;
        opacity: 1;
    }
}

/* ------ */

/* TARJETAS */
.card {
    float: left;
    /* width: 200px; */
    height: 275px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 2px #ddd;
    background: white;
    overflow: hidden;
    /* margin: -30px;
    margin-left: 90px;
    margin-top: 20px;
    margin-bottom: 30px; */
    cursor: pointer;
    transition: all 300ms;
    border-radius: 15px;
}

.card:hover {
    background: linear-gradient(90deg, rgb(5, 10, 116) 0%, rgb(17, 47, 196) 44%, rgb(8, 6, 106)100%);
}

.card:last-child {
    margin-right: 0px;
}

.card .icon {
    display: block;
    width: 100%;
    height: 130px;
    font-size: 50px;
    line-height: 110px;
    text-align: center;
    margin: 0px auto;
    transition: all 300ms;
    color: #031669;
}

.card:hover .icon {
    font-size: 70px;
    color: white;
    animation: showIcon 300ms linear;
}

@keyframes showIcon {
    from {
        transform: translateY(-200%);
    }

    to {
        transform: translateY(0%);
    }
}

.card .category {
    width: 100%;
    height: 50px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    color: #061d86;
    transition: all 300ms;
}

.card:hover .category {
    color: white;
    animation: showCategory 400ms linear;
}

@keyframes showCategory {
    from {
        transform: translateY(-300%);
        color: black;
    }

    to {
        transform: translateY(0%);
        color: white;
    }
}

.card .description {
    color: #061d86;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    font-size: 15px;
    transition: all 300ms;
}

.card:hover .description {
    color: white;
    animation: showDescription 400ms linear;
}

@keyframes showDescription {
    from {
        transform: translateX(-300%);
        color: black;
    }

    to {
        transform: translateX(0%);
    }
}

/* -------- */

/* BARRA LATERAL */
#lateral {
    width: 300px;
    min-height: 1200px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    float: right;
    margin: 60px;
    margin-top: 15px;
}

#lateral h3 {
    width: auto;
    height: 45px;
    line-height: 49px;
    background: url("/public/assets/images/pxgray.png"), #0b269d;
    box-shadow: 0px 1px 0px #393d3f, 1px 2px 0px #393d3f, 2px 3px 0px #393d3f, 3px 4px 0px #393d3f;
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: normal;
    letter-spacing: 2px;
    padding-left: 15px;
    margin-top: 30px;
    margin-bottom: 15px;
    color: white;
}

#lateral h3:first-child {
    margin-top: 0px;
}

.aside-box {
    width: 85%;
    height: 240px;
    margin: 0px auto;
    margin-top: 20px;
    padding-top: 2px;
    background: white;

    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 3px 7px rgba(0, 0, 0, 0.3), 0px 1px white inset, 0px -3px 2px rgba(0, 0, 0, 0.3) inset;
}

#social {
    height: 200px;
}

#social a {
    display: block;
    color: rgb(4, 10, 95);
    text-decoration: none;
    font-size: 30px;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

#social .overlay {
    display: block;
    height: 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    text-shadow: 0px 0px 1px #ccc;
    border: 3px solid white;
    box-shadow: 0px 0px 4px #ccc;
    background: #f4f7fe;
    position: absolute;
    padding: 5px;
    margin-left: 70px;
    margin-top: -60px;
    transform: translateX(-80%);
    opacity: 0;
    transition: all 400ms;
}

#social div:hover .overlay {
    opacity: 1;
    transform: translateX(0%);
}

/* ------------- */

/* ARTICULOS */
#articles {
    width: 760px;
    float: left;
}

#articles h2 {
    display: block;
    width: 120%;
    height: 75px;
    line-height: 75px;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 40px;
    font-weight: normal;
    letter-spacing: 2px;
    background: url("/public/assets/images/pxgray.png"), linear-gradient(90deg, rgb(44, 173, 61) 0%, rgb(52, 153, 79) 44%, rgb(27, 138, 64) 100%);
    box-shadow: 0px 1px 0px #393d3f, 1px 2px 0px #393d3f, 2px 3px 0px #393d3f, 3px 4px 0px #393d3f;
    margin-left: 45px;
    margin-top: 20px;
    margin-bottom: 15px;
    color: white;
}

#articles article {
    width: 120%;
    height: auto;
    border: 1px solid #ccc;
    margin: 40px auto;
    margin-left: 50px;
    padding: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: white;
    color: black;
    box-shadow: 1px 1px 2px #ddd;
}

#articles article h4 {
    display: block;
    font-size: 23px;
    margin: 3px;
}

#articles article h4 a {
    text-decoration: none;
    color: #031669;
}

#articles article h4 a:hover {
    text-decoration: underline;
    color: #031669;
}

#articles .data {
    float: right;
    background: #026637;
    color: white;
    height: 30px;
    line-height: 30px;
    margin: -10px;
    padding: 4px;
    font-size: 13px;
    border-radius: 0px 0px 0px 5px;
}

#articles .data span {
    margin: 6px;
}

#articles article p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    font-family: "TrebuchetMS";
}

#blog {
    height: 100px;
    margin-top: 60px;
    line-height: 100px;
    text-align: center;
    transition: all 1s;
}

#blog a {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 40px;
    letter-spacing: 3px;
    color: #031669;
    text-decoration: none;
    transition: all 1s;
}

#blog:hover {
    background-color: #333;
    border: 10px solid #37bcf9;
    border-radius: 240px;
    box-shadow: 0px 0px 10px gray;
    animation: blink 10s infinite linear;
}

#blog:hover a {
    color: white;
    text-shadow: 1px 1px 1px black, 1px 2px 1px black, 1px 3px 1px black, 1px 4px 1px black, 1px 5px, 1px black, 1px 6px 1px black;
}

@keyframes blink {
    0% {
        border: 10px solid #37bcf9;
    }

    25% {
        border: 10px solid green;
    }

    50% {
        border: 10px solid yellow;
    }

    75% {
        border: 10px solid white;
    }

    100% {
        border: 10px solid #37bcf9;
    }

}