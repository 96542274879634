.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    line-height: 24px;
    font-family: Arial;
}

.table-border {
    border: black 1px solid;
}

.line {
  border-bottom: 1px solid black;
}

.invoice-box .company-logo {
    background: rgba(0, 0, 0, 0) url("https://cobanesmicoope.com/img/logo.svg") no-repeat scroll 0 0;
    width:200px;
    height:60px;
    margin: 20px;
}