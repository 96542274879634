@media (max-width:2500px) {
  .wrap {
    width: 90%;
    margin: 0px auto;
  }

  #articles {
    width: 55%;
  }

}

@media (max-width:1365px) {
  .wrap {
    width: 90%;
    margin: 0px auto;
  }

  #banner {
    width: 100%;
  }

  #banner h1 {
    width: 100%;
    font-size: 25px;
    text-align: center;
    margin: 33px auto;
  }

  #articles {
    width: 50%;
  }

  #articles article {
    width: 120%;
  }

}

@media (max-width:1210px) {
  .card .description {
    display: none;
  }

  .card {
    height: 200px;
  }

  .card .category {
    font-size: 18px;
  }

  #articles {
    width: 45%;
  }
}



@media(max-width: 1080px) {
  #articles {
    float: none;
    width: 75%;

  }

  #lateral {
    float: none;
    width: 100%;
    margin: 0px auto;
    min-height: auto;
    margin-top: 20px;
  }

  #blog2 {
    margin-bottom: 7px;
  }

}

@media(max-width: 880px) {
  #banner h1 {
    font-size: 15px;
  }
}

@media(max-width: 715px) {
  .card .category {
    display: none;
  }

  .card {
    height: 140px;
  }

  #articles h2 {
    font-size: 30px;
  }
}

@media(max-width: 515px) {
  .card {
    height: 100px;
  }

  #articles h2 {
    font-size: 22px;
  }

}

@media(max-width: 515px) {
  #articles h2 {
    font-size: 15px;
  }

  #articles {
    width: 60%;
  }

}

@media(max-width: 390px) {
  #articles {
    width: 70%;
    margin-left: -20px;
  }

}

@media(max-width: 325px) {
  #articles {
    width: 73%;
    margin-left: -30px;
  }

}